<script>
import draggable from "vuedraggable";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Multiselect from 'vue-multiselect'
import ProductService from '@/services/product'
import { VMoney } from 'v-money'
import VueTagsInput from '@johmun/vue-tags-input';

export default {
  page: {
    title: "Produto",
  },
  components: { Layout, PageHeader, Multiselect, draggable, VueTagsInput },
  directives: { money: VMoney },
  data() {
    return {
      title: "",
      product: {},
      productCopy: null,
      productId: null,
      categories: [],
      brands: [],
      features: [],
      tags: [],
      nicknames: [],
      imageToRemove: '',
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailHeight: 100,
        addRemoveLinks: true,
        destroyDropzone: true,
        createImageThumbnails: false
      },
      variation: {},
      variationTypes: [
        { code: 'size', description: 'Tamanho' },
        { code: 'flavor', description: 'Sabor' },
      ],
      productsVariation: [],
      productsKit: [],
      isLoadingKitItems: false,
      isLoadingVariations: false,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },

      nickname: '',
    }
  },
  methods: {
    async loadProduct() {
      const result = await ProductService.getProduct(this.productId);

      this.product = result.data;
      this.formatProductData();
    },
    formatProductData() {
      this.product.price = this.product.price.toFixed(2).replace('.', ',');
      if (!this.product.nicknames) {
        this.product.nicknames = [];
      }
    },
    saveProduct() {
      let loader = this.$loading.show();

      this.product.nicknames = (this.product.nicknames || []).map(item => {
        return item.text ? item.text : item;
      })

      if (this.product.new && !this.productId) {
        if (this.productCopy) {
          this.product.images = this.productCopy.images;
        }
        
        ProductService.createProduct(this.product).then(res => {
          this.product._id = res.data._id;
          this.productId = res.data._id;
          this.title = `Novo Produto -> ${this.product.description}`;

          this.$toast.open('Produto cadastrado com sucesso');

          loader.hide();
        }).catch((err) => {
          loader.hide();

          const errorData = err.response.data;
          if (errorData.code) {
            if (errorData.code === 'E9999') {
              return this.$toast.warning(errorData.msg);
            }
          }

          this.$toast.error('Ocorreu um erro ao gravar o produto');
        })
      } else {
        const data = {
          description: this.product.description,
          barcode: this.product.barcode,
          brand: this.product.brand,
          category: this.product.category,
          details: this.product.details,
          features: this.product.features,
          price: this.product.price,
          tags: this.product.tags,
          nicknames: this.product.nicknames,
        }

        ProductService.updateProduct(this.productId, data).then(() => {
          this.$toast.open('Produto atualizado com sucesso');
          loader.hide();
        }).catch((err) => {
          loader.hide();

          const errorData = err.response.data;
          if (errorData.code) {
            if (errorData.code === 'E9999') {
              return this.$toast.warning(errorData.msg);
            }
          }

          this.$toast.error('Ocorreu um erro ao gravar o produto');
        })
      }
    },
    handleFilesUpload(){      
      const files = this.$refs.file.files;

      for( var i = 0; i < files.length; i++ ) {
        const imageIndex = this.product.images.length;

        this.product.images.push('temp');

        let file = files[i];
        
        let formData = new FormData();
        formData.append('file', file);

        ProductService.uploadImage(this.productId, formData).then(res => {
          this.product.images[imageIndex] = res.data.fileUrl;

          this.$forceUpdate();
          this.$toast.open('Imagem adicionada com sucesso');
        }).catch(() => {
          this.product.images.splice(imageIndex, 1);
          this.$forceUpdate();

          this.$toast.error('Ocorreu um erro ao enviar a imagem');
        })
      }      
    },
    removeImage() {
      this.$bvModal.hide('modal-remove-image');

      const index = this.product.images.indexOf(this.imageToRemove);

      ProductService.removeImage(this.productId, index).then(() => {
        this.loadProduct();
        this.$toast.open('Imagem removida com sucesso');
      }).catch(() => {
        this.$toast.error('Ocorreu um erro ao remover a imagem');
      })
    },
    saveVariations() {
      let loader = this.$loading.show();

      const updateData = {
        hasVariation: this.product.hasVariation,
        variationType: this.product.variationType.code,
        variationDescription: this.product.variationDescription,
        variations: this.product.variations
      }

      ProductService.updateProduct(this.productId, updateData).then(() => {
        loader.hide();
        this.$toast.open('Dados de variação atualizados com sucesso');
      }).catch(() => {
        loader.hide();
        this.$toast.error('Ocorreu um erro ao atualizar o produto');
      })
    },
    saveKit() {
      let loader = this.$loading.show();

      const updateData = {
        isKit: this.product.isKit,
        kitCode: this.product.kitCode,
        kitItems: this.product.kitItems
      }

      ProductService.updateProduct(this.productId, updateData).then(() => {
        loader.hide();
        this.$toast.open('Dados do kit atualizados com sucesso');
      }).catch(() => {
        loader.hide();
        this.$toast.error('Ocorreu um erro ao atualizar o produto');
      })
    },
    saveSettings() {
      let loader = this.$loading.show();

      const updateData = {
        maximumSaleQuantity: this.product.maximumSaleQuantity,
      }

      ProductService.updateProduct(this.productId, updateData).then(() => {
        loader.hide();
        this.$toast.open('Configurações do produto atualizadas com sucesso');
      }).catch(() => {
        loader.hide();
        this.$toast.error('Ocorreu um erro ao atualizar o produto');
      })
    },
    findProductKit(term) {
      this.isLoadingKitItems = true;

      ProductService.getAllProducts(1, { term }).then(res => {
        this.isLoadingKitItems = false;

        this.productsKit = res.data.products;
      }).catch(() => {
        this.isLoadingKitItems = false;
      })
    },
    findProductVariation(term) {
      this.isLoadingVariations = true;

      ProductService.getAllProducts(1, { term }).then(res => {
        this.isLoadingVariations = false;

        this.productsVariation = res.data.products;
      }).catch(() => {
        this.isLoadingVariations = false;
      })
    },
    onDragImage() {
      ProductService.updateProduct(this.productId, { images: this.product.images }).then(() => { })
    },
  },
  mounted() {
    let copyProduct = false;
    this.productId = this.$route.params.id;

    // this.loadVariations();
    
    let loader = this.$loading.show();

    const requests = [
      ProductService.getCategories(),
      ProductService.getBrands(),
      ProductService.getFeatures(),
      ProductService.getTags('?noAuto=true'),
    ]

    if (this.productId) {
      this.title = 'Editando Produto';
      requests.push(ProductService.getProduct(this.productId));
    } else {
      this.title = 'Novo Produto';

      if (this.$route.query && this.$route.query.copy) {
        copyProduct = true;
        requests.push(ProductService.getProduct(this.$route.query.copy));
      } 
    }

    Promise.all(requests).then(res => {
      loader.hide()

      this.categories = res[0].data;
      this.brands = res[1].data;
      this.features = res[2].data;
      this.tags = res[3].data;

      if (this.productId) {
        this.product = res[4].data;

        this.title = `Editando Produto -> ${this.product.description}`;

        if (this.product.variationType) {
          this.product.variationType = this.variationTypes.find(item => {
            return item.code === this.product.variationType;
          })
        }

        this.formatProductData();
      } else {
        this.product = {
          new: true,
          images: []
        }

        if (copyProduct) {
          this.productCopy = res[4].data;

          this.product.description = this.productCopy.description;
          this.product.brand = this.productCopy.brand;
          this.product.category = this.productCopy.category;
          this.product.features = this.productCopy.features;
          this.product.tags = this.productCopy.tags;
          this.product.details = this.productCopy.details;
          this.product.price = this.productCopy.price.toFixed(2).replace('.', ',');
        }
      }
    }).catch(() => {
      loader.hide();
    })
  },
}
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>

    <div class="checkout-tabs" v-if="product">
      <b-tabs pills vertical nav-class="p-0" nav-wrapper-class="col-lg-2 w-100">
        <b-tab active>
          <template v-slot:title>
            <i class="bx bx-package d-block check-nav-icon mt-4 mb-2"></i>
            <p class="font-weight-bold mb-4">Dados Principais</p>
          </template>
          <b-card-text>
            <div class="card">
              <div class="card-body">
                <!-- <h4 class="card-title">Dados do Produto</h4> -->
                <!-- <p class="card-title-desc">Preencha os dados</p> -->
                <form>
                  <div class="row">
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label for="productBarcode">Código de Barras/EAN</label>
                        <input id="productBarcode" name="productBarcode" type="text" class="form-control" v-model="product.barcode" />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-9">
                      <div class="form-group">
                        <label for="productDescription">Nome do Produto</label>
                        <input id="productDescription" name="productDescription" type="text" class="form-control" :maxlength="100" v-model="product.description" />
                        <div v-if="product.description" class="text-left  ">
                          <p
                            class="badge position-absolute"
                            :class="{ 'badge-success': product.description.length !== 100,
                                      'badge-danger': product.description.length === 100 }"
                          >{{ product.description.length }} / 100</p>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-3">
                      <div class="form-group">
                        <label for="price">Preço Original</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span id="validationTooltipUsernamePrepend" class="input-group-text">R$</span>
                          </div>
                          
                          <input
                            id="price"
                            name="price"
                            type="tel"
                            class="form-control"
                            v-model="product.price"
                            v-money="money"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="control-label">Marca</label>
                        <multiselect
                          v-model="product.brand"
                          :options="brands"
                          placeholder="Selecione uma opção"
                          label="description"
                          track-by="_id"
                          selectLabel="Enter para selecionar"
                          selectedLabel="Selecionado"
                          deselectLabel="Enter para remover"
                        ></multiselect>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="control-label">Categoria</label>
                        <multiselect
                          v-model="product.category"
                          :options="categories"
                          placeholder="Selecione uma opção"
                          label="description"
                          track-by="_id"
                          selectLabel="Enter para selecionar"
                          selectedLabel="Selecionado"
                          deselectLabel="Enter para remover"
                        ></multiselect>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="control-label">Características</label>
                        <multiselect
                          v-model="product.features"
                          :options="features"
                          :multiple="true"
                          placeholder="Selecione várias opções"
                          label="description"
                          track-by="_id"
                          selectLabel="Enter para selecionar"
                          selectedLabel="Selecionado"
                          deselectLabel="Enter para remover"
                        ></multiselect>
                      </div>
                    </div>

                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="control-label">Tags</label>
                        <multiselect
                          v-model="product.tags"
                          :options="tags"
                          :multiple="true"
                          placeholder="Selecione várias opções"
                          label="description"
                          track-by="_id"
                          selectLabel="Enter para selecionar"
                          selectedLabel="Selecionado"
                          deselectLabel="Enter para remover"
                        ></multiselect>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label class="control-label">Apelidos</label>
                        <vue-tags-input
                          v-model="nickname"
                          :tags="product.nicknames"
                          placeholder="Adicionar apelido"
                          @tags-changed="newNicknames => product.nicknames = newNicknames"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label for="productdesc">Detalhes</label>
                        <textarea id="productdesc" class="form-control" rows="5" v-model="product.details"></textarea>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-6">
                <router-link
                  tag="a"
                  to="/products"
                  class="btn text-muted d-none d-sm-inline-block btn-link"
                >
                  <i class="mdi mdi-arrow-left mr-1"></i> Voltar para lista de produtos
                </router-link>
              </div>
              <!-- end col -->
              <div class="col-sm-6">
                <div class="text-sm-right">
                  <!-- <router-link tag="a" to="/ecommerce/checkout" class="btn btn-success">
                    Salvar e ir para Imagens
                  </router-link> -->

                  <button type="button" class="btn btn-success btn-rounded mb-2 mr-2" @click="saveProduct">
                    <i class="mdi mdi-plus mr-1"></i> Salvar Dados Principais
                  </button>
                </div>
              </div>
              <!-- end col -->
            </div>
          </b-card-text>
        </b-tab>
        <b-tab :disabled="(product.new && !productId) ? true : false">
          <template v-slot:title>
            <i class="bx bx-images d-block check-nav-icon mt-4 mb-2"></i>
            <p class="font-weight-bold mb-4">Imagens</p>
          </template>
          <b-card-text>
            <div class="card">
              <div class="card-body">
                <h4 class="card-title mb-3">Imagens do Produto</h4>
                <draggable class="images" :list="product.images" @end="onDragImage">                 
                  <div v-for="image in product.images" :key="image" class="image">
                    <div v-if="image !== 'temp' ? true : false" class="image-box">
                      <img :src="image"/>
                      <a href="javascript:void(0);" class="action-icon text-danger" v-b-modal.modal-remove-image @click="imageToRemove = image">
                        <i class="mdi mdi-trash-can font-size-18"></i>
                      </a>
                    </div>

                    <div class="new-image" v-else>
                      <!-- <i class="bx bx-image-add"></i>
                      <p>Clique para adicionar uma nova imagem</p> -->
                      <b-spinner class="m-2" variant="primary" role="status"></b-spinner>
                    </div>
                  </div>                  
                  <input type="file" multiple accept="image/*" name="file" ref="file" v-on:change="handleFilesUpload" />
                </draggable>                
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-6">
                <router-link
                  tag="a"
                  to="/products"
                  class="btn text-muted d-none d-sm-inline-block btn-link"
                >
                  <i class="mdi mdi-arrow-left mr-1"></i> Voltar para lista de produtos
                </router-link>
              </div>
              <!-- end col -->
              <div class="col-sm-6">
                <div class="text-sm-right">
                  <!-- <router-link tag="a" to="/ecommerce/checkout" class="btn btn-success">
                    Salvar e ir para Imagens
                  </router-link> -->

                  <button type="button" class="btn btn-success btn-rounded mb-2 mr-2" @click="$refs.file.click()">
                    <i class="mdi mdi-plus mr-1"></i> Adicionar Imagens
                  </button>
                </div>
              </div>
            </div>
          </b-card-text>
        </b-tab>
        <b-tab :disabled="(product.new && !productId) ? true : false">
          <template v-slot:title>
            <i class="bx bx-add-to-queue d-block check-nav-icon mt-4 mb-2"></i>
            <p class="font-weight-bold mb-4">Variações</p>
          </template>
          <b-card-text>
            <div class="card">
              <div class="card-body">
                <div class="form-group">
                  <div class="custom-control custom-checkbox mb-3">
                    <input type="checkbox" class="custom-control-input" id="need_select_variation" v-model="product.hasVariation" />
                    <label class="custom-control-label" for="need_select_variation">Esse produto possui ou é uma variação?</label>
                  </div>
                </div>

                <form v-if="product.hasVariation">
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label class="control-label">Tipo de Variação</label>
                        <multiselect
                          v-model="product.variationType"
                          :options="variationTypes"
                          placeholder="Selecione uma opção"
                          label="description"
                          track-by="code"
                          selectLabel="Enter para selecionar"
                          selectedLabel="Selecionado"
                          deselectLabel="Enter para remover"
                        ></multiselect>
                      </div>
                    </div>

                    <div class="col-sm-4">
                      <div class="form-group">
                        <label for="productBarcode">Descrição da Variação</label>
                        <input id="productBarcode" name="productBarcode" type="text" class="form-control" v-model="product.variationDescription" />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-8">
                      <div class="form-group">
                        <label class="control-label">Produtos Associados</label>
                        <multiselect
                          v-model="product.variations"
                          :options="productsVariation"
                          :multiple="true"
                          :close-on-select="false"
                          :clear-on-select="true"
                          :loading="isLoadingVariations"
                          @search-change="findProductVariation"
                          placeholder="Selecione várias opções"
                          label="completeDescription"
                          track-by="_id"
                          selectLabel="Enter para selecionar"
                          selectedLabel="Selecionado"
                          deselectLabel="Enter para remover"
                        ></multiselect>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-sm-6">
                <router-link
                  tag="a"
                  to="/products"
                  class="btn text-muted d-none d-sm-inline-block btn-link"
                >
                  <i class="mdi mdi-arrow-left mr-1"></i> Voltar para lista de produtos
                </router-link>
              </div>

              <div class="col-sm-6">
                <div class="text-sm-right">
                  <button type="button" class="btn btn-success btn-rounded mb-2 mr-2" @click="saveVariations">
                    <i class="mdi mdi-plus mr-1"></i> Salvar Variações
                  </button>
                </div>
              </div>
            </div>
          </b-card-text>
        </b-tab>

        <b-tab :disabled="(product.new && !productId) ? true : false">
          <template v-slot:title>
            <i class="bx bx-carousel d-block check-nav-icon mt-4 mb-2"></i>
            <p class="font-weight-bold mb-4">Kit</p>
          </template>
          <b-card-text>
            <div class="card">
              <div class="card-body">
                <div class="form-group">
                  <div class="custom-control custom-checkbox mb-3">
                    <input type="checkbox" class="custom-control-input" id="isKit" v-model="product.isKit" />
                    <label class="custom-control-label" for="isKit">Esse produto é um kit?</label>
                  </div>
                </div>

                <form v-if="product.isKit">
                  <div class="row">
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label for="name">Código do Kit</label>
                        <input id="kitCode" name="kitCode" type="text" class="form-control" v-model="product.kitCode" />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label class="control-label">Produtos do Kit</label>
                        <multiselect
                          v-model="product.kitItems"
                          :options="productsKit"
                          :multiple="true"
                          :close-on-select="false"
                          :clear-on-select="true"
                          :loading="isLoadingKitItems"
                          @search-change="findProductKit"
                          placeholder="Selecione várias opções"
                          label="completeDescription"
                          track-by="_id"
                          selectLabel="Enter para selecionar"
                          selectedLabel="Selecionado"
                          deselectLabel="Enter para remover"
                        ></multiselect>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-sm-6">
                <router-link
                  tag="a"
                  to="/products"
                  class="btn text-muted d-none d-sm-inline-block btn-link"
                >
                  <i class="mdi mdi-arrow-left mr-1"></i> Voltar para lista de produtos
                </router-link>
              </div>

              <div class="col-sm-6">
                <div class="text-sm-right">
                  <button type="button" class="btn btn-success btn-rounded mb-2 mr-2" @click="saveKit">
                    <i class="mdi mdi-plus mr-1"></i> Salvar Dados do Kit
                  </button>
                </div>
              </div>
            </div>
          </b-card-text>
        </b-tab>

        <b-tab :disabled="(product.new && !productId) ? true : false">
          <template v-slot:title>
            <i class="bx bx-cog d-block check-nav-icon mt-4 mb-2"></i>
            <p class="font-weight-bold mb-4">Configurações</p>
          </template>
          <b-card-text>
            <div class="card">
              <div class="card-body">
                <form>
                  <div class="row">
                    <div class="col-sm-2">
                      <div class="form-group">
                        <label for="state">Qtd. Máxima Venda</label>
                        <input
                          id="state"
                          name="state"
                          type="state"
                          class="form-control"
                          v-model="product.maximumSaleQuantity"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-sm-6">
                <router-link
                  tag="a"
                  to="/products"
                  class="btn text-muted d-none d-sm-inline-block btn-link"
                >
                  <i class="mdi mdi-arrow-left mr-1"></i> Voltar para lista de produtos
                </router-link>
              </div>

              <div class="col-sm-6">
                <div class="text-sm-right">
                  <button type="button" class="btn btn-success btn-rounded mb-2 mr-2" @click="saveSettings">
                    <i class="mdi mdi-plus mr-1"></i> Salvar Configurações
                  </button>
                </div>
              </div>
            </div>
          </b-card-text>
        </b-tab>
      </b-tabs>
    </div>

    <b-modal id="modal-remove-image" title="Excluir Imagem" title-class="font-18" centered>
      <p>Confirma a exclusão da imagem selecionada?</p>

      <div slot="modal-footer">
        <b-btn variant="link" @click="$bvModal.hide('modal-remove-image')">Cancelar</b-btn>
        <b-btn variant="primary" @click="removeImage">Excluir</b-btn>
      </div>
    </b-modal>
    
  </Layout>
</template>

<style lang="scss">
  .vue-tags-input {
    max-width: 100% !important;
  }

  .ti-input {
    border-radius: 5px;
    font-size: 13px;
  }

  .ti-tag.ti-valid {
    background-color: #E0103d;
    border-radius: 5px;
    font-size: 13px;
  }

  .images {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    .image {
      display: flex;
      flex-direction: row;
      align-items: center;

      .image-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    img {
      height: 140px;
      margin: 20px;
      min-height: 80px;
    }

    .new-image {
      // border: 2px dashed #a6b0cf !important;
      height: 140px;
      width: 140px;
      border-radius: 6px;
      color: #a6b0cf;
      // font-size: 30px;
      justify-content: center;
      flex-direction: column;
      display: flex;
      align-items: center;
      // padding: 10px;

      p {
        font-size: 10px;
        text-align: center;
        margin: 6px 0px 0px 0px;
      }

      // margin: 20px;
    }

    input[type="file"] {
      display: none;
    }
  }
</style>